import React, { Component, PureComponent } from 'react';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Measure from 'react-measure';
import loadable from '@loadable/component';


// components
import ScrollToTopOnload from "./components/parts/ScrollToTopOnload";
import Nav from "./components/parts/Nav";
import Footer from "./components/parts/Footer";
import SvgGradients from './components/parts/SvgGradients';
import PageLoader from './components/parts/PageLoader.js';


//import About from "./components/sites/About";
//import News from "./components/sites/News";

const Landing = loadable(() => import('./components/sites/Landing'))
//import Landing from "./components/sites/Landing";
const Skills = loadable(() => import('./components/sites/Skills'))
//import Skills from "./components/sites/Skills";
const Skills_single = loadable(() => import('./components/sites/Skills_single.js'))
//import Skills from "./components/sites/Skills_single.js";
const Testimonials = loadable(() => import('./components/sites/Testimonials'))
//import Testimonials from './components/sites/Testimonials';
const Testimonials_single = loadable(() => import('./components/sites/Testimonials_single.js'))
//import Testimonials_single from './components/sites/Testimonials_single.js'
const Clients = loadable(() => import('./components/sites/Clients'))
//import Clients from './components/sites/Clients';
const Contact = loadable(() => import('./components/sites/Contact'))
//import Contact from './components/sites/Contact';
const Content_Page = loadable(() => import('./components/sites/Content_Page'))
//import Content_Page from './components/sites/Content_Page'
const Landing_Y7Group = loadable(() => import('./components/sites/Landing_Y7Group'))
//import Landing_Y7Group from "./components/sites/Landing_Y7Group";
const Not_Found = loadable(() => import('./components/sites/Not_Found'))
//import Not_Found from "./components/sites/Not_Found";

// Images 


// styles
import './assets/styles/Scrollbar.scss';
import './assets/styles/App.scss';

class App extends PureComponent {
    constructor(props) {
        super(props);
        this.siteTitle = 'Y7web';
        this.contentRect = null;
        this.measureItemRef = React.createRef();
        this.footerVisibility = 'block';
        this.state = {
            scrolledToEnd : false,
            modernizrSuccess : false,
            pageLoaded : false,
            footerIsVisible: true,
            footerDimensions: {
                width: 0,
                height: 0
            },
            night: false
        }

        this.setFooterIsVisible = this.setFooterIsVisible.bind(this);
        this.getPageloaderState = this.getPageloaderState.bind(this);
    }

    componentDidMount() {
        const currentDate = new Date();

        if (currentDate.getHours() < 8 || currentDate.getHours() >= 18) {
            this.setState({
                night: true
            });
        }
        //this.modernizrSupportsAll()
        if (true) {
            this.setState({modernizrSuccess: true});
        }
        else {
            window.location.href = 'https://wp-api.y7g.de/';
        }
    }

    disableScrolledToEnd() {
        if (this.state.scrolledToEnd === true) {
            this.setState({scrolledToEnd : false})
        }
    }

    modernizrSupportsAll() {
        let result = true;

        for (let feature in Modernizr) {
            if (typeof Modernizr[feature] === 'boolean' && Modernizr[feature] == false) {
                result = false;
                break;
            }
            else if (feature === 'video' && Modernizr.video.valueOf() == false) {
                result = false;
                break;
            }
        }

        return result;
    }

    reachedScrollYEnd() {
        if (this.state.scrolledToEnd === false) {
            this.setState({scrolledToEnd : true})
        }
    }

    setContentClass(location) {
        let result = '';
        switch (location.pathname.replace('/', '')) {
            case 'referenzen' :
                result = 'behind-footer';
                break;
        }
        
        return result;
    }

    setFooterIsVisible(isVisible = true) {
        this.setState({footerIsVisible: isVisible});
    }


    getPageloaderState(loaderState) {
        this.setState({
            pageLoaded: loaderState
        })
    }

    render() {
        if (this.state.footerIsVisible) {
            this.footerVisibility = 'block';
        } else {
            this.footerVisibility = 'none';
        }

        return ( this.state.modernizrSuccess &&
            <Router>
                <Route
                    render={({ location }) => (
                        <ScrollToTopOnload>
                        <div id="App">
                            <MetaTags>
                                <title>{this.siteTitle}</title>
                                <meta charSet="utf-8" />
                                <meta name="viewport" content="width=device-width, initial-scale=1" />
                                <meta name="description" content="Agentur für moderne Webentwicklung in
                                    Darmstadt bei Frankfurt - Y7 web" />

                                <link rel="apple-touch-icon" sizes="180x180" href="/src/assets/favicons/apple-touch-icon.png" />
                                <link rel="icon" type="image/png" sizes="32x32" href="/src/assets/favicons/favicon-32x32.png" />
                                <link rel="icon" type="image/png" sizes="16x16" href="/src/assets/favicons/favicon-16x16.png" />
                                <link rel="manifest" href="/src/assets/favicons/site.webmanifest" />
                                <link rel="mask-icon" href="/src/assets/favicons/safari-pinned-tab.svg" color="#f39200" />
                                <link rel="shortcut icon" href="/src/assets/favicons/favicon.ico" />
                                <meta name="msapplication-TileColor" content="#222222" />
                                <meta name="msapplication-config" content="/src/assets/favicons/browserconfig.xml" />
                                <meta name="theme-color" content="#222222" />

                                <meta property="og:title" content="Webagentur Darmstadt Frankfurt - Y7 web" />
                                <meta property="og:description" content="Ihr Ansprechpartner für moderne und
                                    zukunftsorientierte Webentwicklung in Darmstadt bei Frankfurt." />
                                <meta property="og:image" content="src/assets/images/y7title.jpg" />
                                <meta property="og:url" content="localhost:8080/" />
                                <meta property="og:type" content="website" />
                                <meta property="og:locale" content="de_DE" />
                                <meta property="og:site_name" content="Y7 web" />
                                <meta name="twitter:card" content="summary_large_image" />
                                <meta name="twitter:image:alt" content="Y7 web Titelbild" />
                            </MetaTags>
                            <SvgGradients />
                            <TransitionGroup id="content" className={this.setContentClass(location)}>
                                <CSSTransition
                                    key={location.pathname}
                                    classNames={{
                                        enter: 'fa-enter',
                                        enterActive: 'fa-enter-active',
                                        enterDone: 'fa-enter-done',
                                        exit: 'fa-exit',
                                        exitActive: 'fa-exit-active',
                                        exitDone: 'fa-exit-done'
                                    }}
                                    timeout={{
                                        enter: 750,
                                        exit: 750,
                                    }}
                                    
                                >
                                    <Switch location={location}>
                                        <Route path="/landing-y7g" exact component={Landing_Y7Group} />    

                                        <Route path="/" exact render={(props) => <Landing {...props} sendPageloaderState={this.getPageloaderState} footerdimensions={this.state.footerDimensions} siteTitle={this.siteTitle} night={this.state.night} />} />
                                        {/*
                                        <Route path="/die-medienagentur" exact render={(props) => <About {...props} siteTitle={this.siteTitle} /> } />
                                        <Route path="/neuigkeiten" exact render={(props) => <News {...props} siteTitle={this.siteTitle} /> } />
                                        <Route path="/neuigkeiten/:title" exact render={(props) => <News {...props} siteTitle={this.siteTitle} /> } />
                                        <Route path="/neuigkeiten/kategorie/:kategorie" exact render={(props) => <News {...props} siteTitle={this.siteTitle} /> } />
                                        <Route path="/neuigkeiten/tag/:tag" exact render={(props) => <News {...props} siteTitle={this.siteTitle} /> } />
                                        */}
                                        <Route path="/development" exact render={(props) => <Skills {...props} sendPageloaderState={this.getPageloaderState} footerdimensions={this.state.footerDimensions} siteTitle={this.siteTitle} />} />
                                        <Route path="/development/:leistung" exact render={(props) => <Skills_single {...props} sendPageloaderState={this.getPageloaderState} footerdimensions={this.state.footerDimensions} siteTitle={this.siteTitle} />} />
                                        <Route path="/referenzen" exact render={(props) => <Testimonials {...props} sendPageloaderState={this.getPageloaderState} scrolledToEnd={this.state.scrolledToEnd} footerdimensions={this.state.footerDimensions} siteTitle={this.siteTitle} footerIsVisible={this.setFooterIsVisible} />} />
                                        <Route path="/referenzen/:projekt" exact render={(props) => <Testimonials_single {...props} sendPageloaderState={this.getPageloaderState} footerdimensions={this.state.footerDimensions} siteTitle={this.siteTitle} />} />
                                        {/*
                                        <Route path="/referenzen/:kunde" exact component={Testimonials} />
                                        <Route path="/referenzen/:kunde/:projekt" exact component={Testimonials} />
                                        */}
                                        <Route path="/klienten" exact render={() => <Clients sendPageloaderState={this.getPageloaderState} footerdimensions={this.state.footerDimensions} siteTitle={this.siteTitle} />} />
                                        <Route path="/service" exact render={() => <Contact sendPageloaderState={this.getPageloaderState} footerdimensions={this.state.footerDimensions} siteTitle={this.siteTitle} />} />
                                        <Route path={["/datenschutz", "/impressum"]} exact render={() => <Content_Page sendPageloaderState={this.getPageloaderState} footerdimensions={this.state.footerDimensions} siteTitle={this.siteTitle} />} />

                                        <Route render={() => <Not_Found sendPageloaderState={this.getPageloaderState} footerdimensions={this.state.footerDimensions} siteTitle={this.siteTitle} />} /> 
                                    </Switch>
                                </CSSTransition>
                            </TransitionGroup>

                                <Nav location={location} night={this.state.night} />

                                <Measure ref={this.measureItemRef} bounds offset onResize={contentRect => {
                                    this.setState({footerDimensions: contentRect.bounds})
                                }}>
                                    {({measureRef}) =>

                                        <div id="footer" style={{display: this.footerVisibility}}>
                                            <Footer measureRef={measureRef} />
                                        </div>

                                    }
                                </Measure>

                                <PageLoader finishedloading={this.state.pageLoaded} />
                        </div>
                        </ScrollToTopOnload>
                    )
                } />
            </Router>
        );
    }
}

export default App;