import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";
import Measure from 'react-measure';
import MediaQuery from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { // Icons need to be registered in getIcon() too
    faBars,
    faAngleDown,
    faPlus,
    faMinus,
    faTimes
} from '@fortawesome/free-solid-svg-icons';

import Logo from '../parts/Logo.js';

import '../../assets/styles/Nav.scss';

const If = (props) => {
    const condition = props.condition || false;
    const positive = props.then || null;
    const negative = props.else || null;
    
    return condition ? positive : negative;
  };
  

const DesktopHeader = (props) => {
    return (
        <li id={'navLink_home'}>
            <NavLink to="/" className="homelink">
                <Logo height="50" uniqueid="header" animation={false} />
                <svg id="logoCloud" xmlns="http://www.w3.org/2000/svg" width="180" viewBox="0 0 226.61 115.36">
                    <path id="logoCloudPath" className="cls-1" fill="#fff"
                        d="M201,64.36a25.24,25.24,0,0,0-6.36.84l-3,.78-3.49.9-2.21-6.35a41,41,0,0,0-49.38-26.34L129.77,36l-2.05-6.71A41,41,0,0,0,88.25,0h0A40.93,40.93,0,0,0,47.4,46.92l1.48,10.77L38.35,55a30.67,30.67,0,1,0-7.88,60.37H199.86l.27,0,1.06,0h0a25.5,25.5,0,1,0-.21-51Z" />
                </svg>
            </NavLink>
        </li>
    )
}
const MobileHeader = (props) => {
    const getIcon = (mobileNavActive) => {
        return (mobileNavActive ? faTimes : faBars);
    }

    return (
        <>
            <NavLink to="/" className="homelink" onClick={props.hideMobileNav}>
                <Logo height="50" uniqueid="header" animation={false} />
                <svg id="logoCloud" xmlns="http://www.w3.org/2000/svg" width="180" viewBox="0 0 226.61 115.36">
                    <path id="logoCloudPath" className="cls-1" fill="#fff"
                        d="M201,64.36a25.24,25.24,0,0,0-6.36.84l-3,.78-3.49.9-2.21-6.35a41,41,0,0,0-49.38-26.34L129.77,36l-2.05-6.71A41,41,0,0,0,88.25,0h0A40.93,40.93,0,0,0,47.4,46.92l1.48,10.77L38.35,55a30.67,30.67,0,1,0-7.88,60.37H199.86l.27,0,1.06,0h0a25.5,25.5,0,1,0-.21-51Z" />
                </svg>
            </NavLink>
            <div className="mobile-page-title-container">{props.pageTitle}</div>
            <a id="mobileNavTrigger" onClick={props.toggleMobileNav}>
                {/* <FontAwesomeIcon icon={getIcon(props.mobileNavActive)} size="2x" /> */}
                <div id="nav-icon" className={props.mobileNavActive ? 'open' : ''}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </a>
        </>
    )
}

const Submenu = (props) => {
    const getItemUrl = (item) => {
        let result = item.url;
        if (props.type === 'hash') {
            let pos = item.url.lastIndexOf('/');
            result = item.url.substring(0, pos) + '#' + item.url.substring(pos + 1);
        }
        return result;
    }
    
    return (
        <ul className={'submenu' + (props.isActive ? ' is-active' : '')}>
            {props.navEntry.childItems.map(item =>(
                <li key={item.ID}>
                    <NavLink
                        onClick={props.onClick}
                        to={getItemUrl(item)}
                    >
                        <span><span>{item.title}</span></span>
                    </NavLink>
                </li>
            ))}
        </ul>
    )
}

class NavItem extends Component {
    constructor(props) {
        super(props);
        this.contentRect = null;
        this.measureItemRef = React.createRef();

        this.state = {
            activeSubmenu: false,
        }

        this.toggleSubmenu = this.toggleSubmenu.bind(this);
    }

    getMenuExpanderIcon() {
        let result;
        if (!this.props.isMobile) {
            result = <FontAwesomeIcon icon={faAngleDown} size="1x" />
        }
        else {
            this.state.activeSubmenu
                ? result = <FontAwesomeIcon icon={faMinus} size="1x" />
                : result = <FontAwesomeIcon icon={faPlus} size="1x" />
        }

        return result;
    }

    toggleSubmenu() {
        this.setState({activeSubmenu: (!this.state.activeSubmenu && this.props.isMobile)});
    }

    setClasses() {
        let isActive = this.props.active;
        let hasSubmenu = this.props.navEntry.childItems != undefined;
        let classString = '';

        if (isActive) classString += 'active';
        if (hasSubmenu) classString += ' main-item';

        return classString;
    }
    
    render() {
        let navEntry = this.props.navEntry;

        return (
            <Measure ref={this.measureItemRef} bounds offset onResize={contentRect => this.contentRect = contentRect}>
                {({measureRef}) =>
                    <li
                        id={'navLink_' + navEntry.ID}
                        ref={measureRef}
                        data-svg={'svgToNavLink_' + navEntry.ID}
                        className={ this.setClasses() }
                    >
                        <NavLink onClick={this.props.onClick} className={(this.state.activeSubmenu) ? 'is-active' : ''} to={navEntry.url}>
                            <span><span>{navEntry.title}</span></span>
                        </NavLink>
                        {/*this.props.navEntry.childItems &&
                            <>
                                <div className="submenu-expander" onClick={this.toggleSubmenu}>
                                    {this.getMenuExpanderIcon()}
                                </div>
                                <Submenu type="hash" onClick={this.props.onClick} navEntry={this.props.navEntry} isActive={this.state.activeSubmenu && this.props.isMobile} />
                            </>
                        */}
                    </li>
                }
            </Measure>
        )
    }
}

class Nav extends Component {
    constructor(props) {
        super(props);
        this.svgPaddingBottom = 10;      
        this.isHome = false;
        this.isUnloading = false;

        this.state = {
            width : null,
            isLoading: true,
            navItems: [],
            mobileNavActive: false
        }
        this.toggleMobileNav = this.toggleMobileNav.bind(this);
        this.hideMobileNav = this.hideMobileNav.bind(this);
    }

    componentDidCatch(error, info) {
        console.log(`error: ${error} | info: ${info}`);
    }

    componentDidMount() {
        this.setState({
            isLoading: true,
        });
        this.isUnloading = false;
        fetch('https://wp-api.y7g.de/wp-json/menus/v1/menus/locations/mainnav')
            .then(response => response.json())
            .then(responseJson => this.setupMenuUrls(responseJson))
            .then(responseJson => this.setupSubmenus(responseJson))
            .then(updatedMenu => {
                if (this.isUnloading) {
                    return;
                }
                this.setState({
                    navItems : updatedMenu,
                    isLoading : false
                })
            })
    }

    componentWillUnmount() {
        this.isUnloading = true;
    }

    setupSubmenus(responseJson) {
        let childItems = {};
        let menuItems = responseJson.filter(item => { // Remove all child items from menu
            let result = true;
            let parentItemId = item.menu_item_parent;
            if (parentItemId != 0) {
                (parentItemId in childItems) // Store children in childItems, grouped by parent ID
                    ? childItems[parentItemId].push(item)
                    : childItems[parentItemId] = [item];
                result = false;
            }
            return result;
        });
        
        // Assign child groups to their respective parents
        Object.keys(childItems).forEach(key => {
            let parentItem = menuItems.find(el => parseInt(key) === el.ID);
            parentItem.childItems = childItems[key];
        })

        return menuItems;
    }

    setupMenuUrls(responseJson) {
        return responseJson.map(item => {item.url = this.setupUrl(item.url); return item});
    }

    setupUrl(url) {
        url = url.replace('https://wp-api.y7g.de', '');
        url = url.charAt(0).replace('#', '/') + url.slice(1);

        return url;
    }

    toggleMobileNav() {
        const mobileNav = this.state.mobileNavActive;
        this.setState({
            mobileNavActive: !mobileNav
        })
    }

    hideMobileNav() {
        this.setState({
            mobileNavActive: false
        })
    }

    buildMobilePageTitle() {
        let activeNavEntry = this.state.navItems.find(navEntry => location.pathname.includes(navEntry.url));

        return ( activeNavEntry &&
            <>
                <span className="page-title">
                    {activeNavEntry.title}
                </span>
            </>
        );
    }


    setNavClasses(isMobile, mobileNavActive) {
        let className = 'initialized ';
        if (this.props.location.pathname === "/") {
            className += 'homepage';
            this.isHome = true;
        }
        else {
            this.isHome  = false;
        }
        if (isMobile) {
            className += ' is-mobile';
        }
        if (mobileNavActive) {
            className += ' is-active';
        }
        if (this.props.night) {
            className += ' nightmode'
        }
        return className;
    }

    render() {
        return ( !this.state.isLoading &&
            <MediaQuery maxWidth={768}>
                {(isMobile) => (
                    <React.Fragment>
                    <nav id="mainNav" className={this.setNavClasses(isMobile, this.state.mobileNavActive)}>
                        { isMobile &&
                            <MobileHeader
                                pageTitle={this.buildMobilePageTitle()}
                                toggleMobileNav={this.toggleMobileNav}
                                hideMobileNav={this.hideMobileNav}
                                mobileNavActive={this.state.mobileNavActive}
                            />
                        }
                        <ul className={this.state.mobileNavActive ? 'active' : null}>
                            { !isMobile &&
                                <DesktopHeader />
                            }
                            {this.state.navItems.map((navEntry) => (
                                <NavItem 
                                    key={navEntry.ID}
                                    navEntry={navEntry}
                                    active={location.pathname.includes(navEntry.url)}
                                    onClick={this.hideMobileNav}
                                    isMobile={isMobile}
                                />
                            )
                            )}
                        </ul>
                    </nav>
                    { isMobile &&
                        <div 
                            id="mobileNavOutside"
                            className={this.state.mobileNavActive ? 'active' : null}
                            onClick={this.hideMobileNav}
                        ></div>
                    }
                    </React.Fragment>
                )}
            </MediaQuery>
        );
    }
}

export default Nav;