import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, NavLink } from "react-router-dom";
import dateFormat from 'dateformat';
import { TweenMax, TimelineMax} from 'gsap/all';
import ScrollMagic from '../../assets/js_wrapper/ScrollMagicWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { // Icons need to be registered in getIcon() too
    faSwatchbook,
    faPhone,
    faFax,
    faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import { 
    faReact,
    faWordpress,
    faFacebook,
    faInstagram,
    faYoutube
} from '@fortawesome/free-brands-svg-icons';
import Logo from '../parts/Logo.js';


import '../../assets/styles/Footer.scss';


const UpperPart = (props) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col footer-logo-container">
                    <Logo slogan={true} darkmode={true} uniqueid="footer" animation={false} />
                </div>
                <div className="col content after-logo">
                    <div className="innerFooterContent">
                        <h4><span>Anschrift</span></h4>
                        <div className="contactlist">
                            <div className="contactitem without-label">
                                <strong>Y7web GmbH (i.G.)</strong>
                            </div>
                            <div className="contactitem without-label">
                                Rheinstraße 95
                            </div>
                            <div className="contactitem without-label">
                                64295 Darmstadt
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col content">
                    <div className="innerFooterContent">
                        <h4><span>Schnellkontakt</span></h4>
                        <div className="contactlist">
                            <div className="contactitem">
                                <div className="label">
                                <FontAwesomeIcon icon={faPhone} size="1x" />
                                </div>
                                <div className="item">
                                    06151/8009569
                                </div>
                            </div>
                            <div className="contactitem">
                                <div className="label">
                                <FontAwesomeIcon icon={faFax} size="1x" />
                                </div>
                                <div className="item">
                                    06151/8009564
                                </div>
                            </div>
                            <div className="contactitem">
                                <div className="label">
                                <FontAwesomeIcon icon={faEnvelope} size="1x" />
                                </div>
                                <div className="item">
                                    info@y7web.de
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col content">
                    <div className="innerFooterContent">
                        <h4><span>Social Media</span></h4>
                        <div className="contactlist">
                            <div className="contactitem">
                                <a className="" href="#">
                                    <div className="label">
                                        <span><FontAwesomeIcon icon={faFacebook} size="1x" /></span>
                                    </div>
                                    <div className="item">
                                        Facebook
                                    </div>
                                </a>
                            </div>
                            <div className="contactitem">
                                <a className="" href="#">
                                    <div className="label">
                                        <span><FontAwesomeIcon icon={faInstagram} size="1x" /></span>
                                    </div>
                                    <div className="item">
                                        Instagram
                                    </div>
                                </a>
                            </div>
                            <div className="contactitem">
                                <a className="" href="#">
                                    <div className="label">
                                        <span><FontAwesomeIcon icon={faYoutube} size="1x" /></span>
                                    </div>
                                    <div className="item">
                                        Youtube
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const LowerPart = (props) => {
    let date = new Date()

    return(
        <div id="absoluteFooter">
            <div className="container">
                <div className="row">
                    <div className="col copyright">
                        
                            <div>&copy; {dateFormat(date, "yyyy")} Y7web GmbH</div>
                            <div><span className="copyright-line">|</span>powered by<FontAwesomeIcon icon={faReact} size="1x" /> React &amp; <FontAwesomeIcon icon={faWordpress} size="1x" /> Wordpress</div>
                        
                    </div>
                    <div className="col imprint">
                        <div>
                            <Link to={'/service'}>Kontakt</Link> | <Link to={'/datenschutz'}>Datenschutz</Link> | <Link to={'/impressum'}>Impressum</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

class Footer extends Component {
    constructor(props) {
        super(props);
        
        this.phone = '06151/8009569';
        this.email = 'info@y7web.de';

        // Animation related start
            this.footerRef = null;
            this.tFooter = new TimelineMax();

            // ScrollMagic
            this.controller = new ScrollMagic.Controller();
            this.sceneFooterAppears = null;
        // Animation related end

        this.setFooterFade = this.setFooterFade.bind(this);
    }

    // Animation builders start
    buildFadeIn(el, duration = 1) {
        let tween = TweenMax.fromTo(
            el,
            duration,
            {autoAlpha: 0},
            {autoAlpha: 1}
        );

        return tween;
    }

    setFooterFade() {
        let footer = document.getElementById('footer');
        let footerTrigger = document.getElementById('footer-trigger');

        this.tFooter.clear().kill();
        this.tFooter = new TimelineMax();

        this.tFooter.addLabel('animationStart');
        this.tFooter.add(this.buildFadeIn(footer, 0.5), 'animationStart+=0.2');

        if (this.sceneFooterAppears != null) {
            this.sceneFooterAppears.destroy(true);
        }
        // iOS 100vh scroll Bugfix requires this setup for pages with height = 100vh + footer height. i.E home
        let isHome = location.pathname === '/';
        let animationHook = (isHome ? 'onLeave' : 'onEnter');
        let offset = (isHome ? -window.innerHeight - 59 : -10 ) // 59 = footer height. 10 = just some tiny offset

        this.sceneFooterAppears = new ScrollMagic.Scene({
            triggerElement: footerTrigger,
            triggerHook: animationHook,
            offset: offset
        })
        .setTween(this.tFooter)

        this.sceneFooterAppears.addTo(this.controller);
    }

    componentDidMount() { 
        this.setFooterFade();
    }

    componentDidUpdate() {
        if (location.pathname === '/') { // iOS Bugfix requires recalculating footer Animation on resize for home
            window.addEventListener('resize', this.setFooterFade);
        }
        else {
            window.removeEventListener('resize', this.setFooterFade);
        }
        this.setFooterFade();
    }

    render() {
        return (
            <>
            <div id="footer-trigger"></div>
            <div className="footer-inner" ref={this.props.measureRef}>
                {location.pathname != '/' && false && //never render upper footer
                    <UpperPart />
                }
                <LowerPart />
            </div>
            </>
        )
    }
}

export default Footer;
  